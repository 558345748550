import style from "./deletepopup.module.scss";

const DeletPopup = ({ closeClick, deleteClick }) => {
  return (
    <div className={style.popup}>
      <div className={style.wrapper}>
        <div className={style.bar}>
          <h5>Silmek istediğinize emin misiniz?</h5>
          <button
            className={style.close}
            onClick={(e) => {
              e.preventDefault();
              closeClick();
            }}
          >
            X
          </button>
        </div>
        <div className={style.content}>
          <button
            onClick={(e) => {
              e.preventDefault();
              deleteClick();
            }}
          >
            Sil
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              closeClick();
            }}
          >
            İptal
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletPopup;
