import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import style from "./contentpopup.module.scss";

const ContentPopup = ({
  closeClick,
  type,
  url,
  isNavigate,
  leftClick,
  rightClick,
}) => {
  return (
    <div className={style.contentPopup}>
      <button onClick={closeClick} className={style.close}>
        X
      </button>

      {type === "video" ? (
        <video autoPlay muted controls>
          <source src={url} type="video/mp4" />
        </video>
      ) : (
        <div className={style.contentWrapper}>
          {isNavigate ? (
            <div className={style.buttonWrapper}>
              <button onClick={leftClick}>
                <ArrowLeftIcon />
              </button>
              <button onClick={rightClick}>
                <ArrowRightIcon />
              </button>
            </div>
          ) : null}
          <img key={url} src={url} />
        </div>
      )}
    </div>
  );
};

export default ContentPopup;
