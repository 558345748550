import { SIGN_OUT, USER_LOGIN, GET_ACCOUNT, ACCEPT_AGREEMENT } from "../type";

export const userLogin = (payload) => {
  return {
    type: USER_LOGIN,
    payload: payload,
  };
};

export const signOut = (payload) => {
  return {
    type: SIGN_OUT,
    payload: payload,
  };
};

export const getAccount = (payload) => {
  return {
    type: GET_ACCOUNT,
    payload: payload,
  };
};

export const acceptAgreement = (payload) => {
  return {
    type: ACCEPT_AGREEMENT,
    payload: payload,
  };
};
