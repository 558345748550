import { USER_LOGIN, SIGN_OUT, GET_ACCOUNT, ACCEPT_AGREEMENT } from "../type";

const initialState = {
  user: {
    token: "",
  },
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        user: {
          ...action.payload.userData,
          token: action.payload.token,
        },
      };
    case SIGN_OUT:
      return {
        ...state,
        user: {
          token: "",
        },
      };

    case GET_ACCOUNT:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case ACCEPT_AGREEMENT:
      return {
        ...state,
        user: {
          ...state.user,
          isAgreement: true,
        },
      };
    default:
      return state;
  }
}
